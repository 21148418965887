import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    users: [],
    circles: [],
    companyRequests: [],
    payments: [],
    sms: [],
    push_notifications: [],
    monthlyUsers: [],
    monthlyCircles: [],
    monthlyPayments: [],
    selectedCircle: null,
    token: localStorage.getItem('token') || undefined
  },
  getters: {
    isAuthenticated: state => state.token !== undefined,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
    },
    setCircles(state, circles) {
      state.circles = circles
    },
    setCompanyRequests(state, companyRequests) {
      state.companyRequests = companyRequests
    },
    setPayments(state, payments) {
      state.payments = payments
    },
    setMonthlyUsers(state, monthlyUsers) {
      state.monthlyUsers = monthlyUsers
    },
    setMonthlyCircles(state, monthlyCircles) {
      state.monthlyCircles = monthlyCircles
    },
    setMonthlyPayments(state, monthlyPayments) {
      state.monthlyPayments = monthlyPayments
    },
    selectUser(state, user) {
      state.selectedUser = user
    },
    selectCircle(state, circle) {
      state.selectedCircle = circle
    },
    authenticate(state, token) {
      state.token = token
    },
    logout(state) {
      state.token = undefined
    }
  },
  actions: {
    async fetchData({commit, reject}) {
      const {data} = await Vue.axios.get('dashboard')
      if (!data) {
        reject()
      }
      commit('setUsers', data.users)
      commit('setCircles', data.circles)
      commit('setCompanyRequests', data.companyRequests)
      commit('setPayments', data.transactions)
      commit('setMonthlyUsers', data.monthlyUsers)
      commit('setMonthlyCircles', data.monthlyCircles)
      commit('setMonthlyPayments', data.monthlyTransactions)
    },
    authenticate({commit}, user) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('login', user).then(resp => {
          localStorage.setItem('token', resp.data.api_token)
          commit('authenticate', resp.data.api_token)
          resolve()
        }).catch(() => {
          reject()
        })
      })
    }
  },
  modules: {
  }
})
