import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/dashboard')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
      },
      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
      },
      {
        path: '/users/:user',
        name: 'user',
        component: () => import(/* webpackChunkName: "users" */ '../views/User.vue')
      },
      {
        path: '/circles',
        name: 'circles',
        component: () => import(/* webpackChunkName: "circles" */ '../views/Circles.vue')
      },
      {
        path: '/circles/:circle',
        name: 'circle',
        component: () => import(/* webpackChunkName: "circles" */ '../views/Circle.vue')
      },
      {
        path: '/payments',
        name: 'payments',
        component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue')
      },
      {
        path: '/admins',
        name: 'admins',
        component: () => import(/* webpackChunkName: "admins" */ '../views/Admins.vue')
      },
      {
        path: '/SMS',
        name: 'sms',
        component: () => import(/* webpackChunkName: "sms" */ '../views/SMS.vue')
      },
      {
        path: '/push-notifications',
        name: 'push_notifications',
        component: () => import(/* webpackChunkName: "push_notifications" */ '../views/PushNotifications.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
